<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <br>
    <br>
    <p style="font-size: medium;">
      Please select one of the unpaid invoices from the dropdown menu below to proceed with your payment.
      Visit <a href="https://lpc.org.za/contact/#contact-form" target="_blank" rel="noopener">LPC Site</a> for more info.
    </p>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
