<template>
  <div class="container mt-5" v-if="!showCheckout">
    <div class="card" >
      <div class="card-header">
        <h2 align="center">Pay<span style="color: green;">Prov</span> Gateway</h2>
      </div>
      <div class="card-body" style="border-radius: 10px !important;">
        <form @submit.prevent="handlePayment">
          <div class="mb-3">
            <label for="name" class="form-label">Full Names</label>
            <input type="text" class="form-control" id="name" v-model="fullnames" required readonly>
          </div>

          <div class="mb-3">
            <label for="province" class="form-label">Province</label>
            <select class="form-control" id="province" v-model="localData.province" required disabled>
              <option value="" disabled>Select a province</option>
              <option v-for="(province, index) in provinces" :key="index" :value="province">{{ province }}</option>
            </select>
          </div>

          <div class="mb-3">
            <label for="amount" class="form-label">Amount (ZAR)</label>
            <input type="number" class="form-control" id="amount" v-model="localData.inv_total" step="0.01" min="0" required readonly>
          </div>

          <button 
            style="width: 100%; margin-bottom: 20px; margin-top: 20px; border-radius: 20px; font-weight: bold; font-size: medium;" 
            type="button"
            @click="initiatePayment"
            class="btn btn-success btn-block"
            :disabled="localData.inv_total === undefined"
          >
            {{ localData.inv_total === undefined ? 'Select Unpaid Invoice' : 'Proceed to Payment' }}
          </button>
      
        </form>

      </div>
    </div>
  </div>
  
  <div v-else style="height: 700px; margin-top: 4px !important;" id="payment-form"></div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    invData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      localData: { ...this.invData },
      provinces: [
        "Eastern Cape", "Free State", "Gauteng", "KwaZulu-Natal",
        "Limpopo", "Mpumalanga", "Northern Cape", "North West", "Western Cape"
      ],
      peachLoaded: false,
      showCheckout: false,
      loading: false,
      fullnames: localStorage.getItem('user')
    };
  },
  watch: {
    invData: {
      handler(newVal) {
        console.log('invData changed:', newVal);
        this.localData = { ...newVal };
      },
      deep: true
    }
  },
  methods: {
    startLoading() {
    this.loading = true;
    
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  },

    loadPeachPaymentsSDK() {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://sandbox-checkout.peachpayments.com/js/checkout.js';
        script.onload = () => {
          this.peachLoaded = true;
          resolve();
        };
        script.onerror = () => {
          console.error('Failed to load Peach Payments SDK');
          reject(new Error('Failed to load Peach Payments SDK'));
        };
        document.head.appendChild(script);
      });
    },

    async getCheckoutId() {
      try {
        const response = await axios.post('https://payprovs-edddc4gpenbrbtgz.southafricanorth-01.azurewebsites.net/api/v1/checkout', {
          amount: (this.localData.inv_total),
          merchantTransactionId: (this.localData.inv)
        });
        return response.data.checkoutId;
      } catch (error) {
        console.error('Error fetching checkout ID:', error);
        alert('Failed to initiate payment. Please try again later.');
        throw error;
      }
    },
    async updateInvoice() {
      try {
        const response = await axios.post('https://payprovs-edddc4gpenbrbtgz.southafricanorth-01.azurewebsites.net/api/v1/payment', {
          amt: (this.localData.inv_total),
          inv: (this.localData.inv),
          memno: this.localData.memno,
          ref: this.localData.inv_db,
        });
        return response.data.checkoutId;
      } catch (error) {
        console.error('Error fetching checkout ID:', error);
        alert('Failed to initiate payment. Please try again later.');
        throw error;
      }
    },
    async initiatePayment() {
      
      await this.loadPeachPaymentsSDK();

      if (!this.peachLoaded) {
        console.error('Peach Payments SDK not loaded yet');
        return;
      }

      this.loading = true;
      this.showCheckout = true;

      try {
        
        const checkoutId  = await this.getCheckoutId();
        const checkout = window.Checkout.initiate({
          checkoutId: checkoutId,
          key: '8ac7a4c9924c2a1101924c5453ff004c',
          options: {
            theme: {
              brand: {
                primary: "#1C7ED6",
                secondary: "#FF0000"
              }
            },
            events: {
              onCompleted: () => {
                alert('Payment successful!');
                this.updateInvoice();
              },
              onCancelled: (event) => {
                alert('Payment cancelled.');
                console.log('Payment cancelled:', event);
              },
              onExpired: (event) => {
                alert('Payment expired.');
                console.log('Payment expired:', event);
              }
            },
            ordering: {
              CARD: 1,
              EFTSECURE: 4,
              MASTERPASS: 2,
              CAPITECPAY: 3,
            },
          }
        });

        checkout.render("#payment-form");
        this.loading = false;

      } catch (error) {
        this.showCheckout = false;
        this.loading = false;
        console.error('Error initiating payment:', error);
      }
    },
    cancel() {
      console.log('Payment cancelled');
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 600px;
}
</style>
